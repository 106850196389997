/* eslint-disable no-useless-escape */

import { OutputData } from '@editorjs/editorjs';
import { IPair } from 'api/api-courses';
import { IColor, IOption } from 'models';

export const DEFAULT_USERS_PER_PAGE = 10;
export const RECIPIENTS_PER_PAGE = 30;
export const TEACHERS_PER_PAGE = 1000;
export const WORDS_PER_PAGE = 6;
export const PAGE_RANGE = 4;

export const DEFAULT_ERROR_MESSAGE = 'Что-то пошло нет так';
export const NO_INTERNET_ERROR_MESSAGE = 'Отсутствует интернет-соединение';
export const NO_INTERNET_WARNING_MESSAGE = 'Пожалуйста, проверьте ваше интернет-соединение';

export const USERS_TABLE_HEADERS = ['Имя', 'E-mail', 'Роль', 'Статус', 'Действия'];

export enum USER_ROLE {
  ADMIN = 'Администратор',
  TEACHER = 'Учитель',
  ASSISTANT = 'Ассистент',
  STUDENT = 'Ученик'
}

export const USER_ROLES = [
  USER_ROLE.ADMIN,
  USER_ROLE.TEACHER,
  USER_ROLE.ASSISTANT,
  USER_ROLE.STUDENT
];
export const USER_ROLES_ORDER = [
  USER_ROLE.ADMIN,
  USER_ROLE.TEACHER,
  USER_ROLE.ASSISTANT,
  USER_ROLE.STUDENT
];
export const USER_ROLES_OPTIONS: IOption[] = [...USER_ROLES].map(role => ({
  value: role,
  label: role
}));

export const WORDS_TABLE_HEADERS = [
  '№',
  'Картинка',
  'На корейском',
  'На русском',
  'Действия',
  'Словари'
];

export enum COURSE_PAGE_OPTIONS {
  INFO = 'Информация курса',
  CONTENT = 'Содержание',
  CHECKLIST = 'Чек-лист',
  ANALYTICS = 'Аналитика',
  STUDENTS = 'Ученики'
}

export enum CHECKLIST_OPTIONS {
  STEPS = 'steps',
  REVIEW = 'review',
  UPDATE_STATUS = 'update-status'
}

export enum COURSES_PAGE_OPTIONS {
  COURSES = 'courses',
  CREATE_COURSE = 'createCourse'
}

export const COURSES_STATUSES = [
  'Опубликован для всех',
  'Опубликован только по подписке',
  'Не опубликован'
];
export const COURSES_STATUSES_FILTER = [
  'Все курсы',
  'Опубликованные для всех',
  'Опубликованные по подписке',
  'Не опубликованные'
];

export enum CourseLevel {
  easy = 'Начинающий',
  medium = 'Средний',
  hard = 'Сложный'
}

export const COURSES_LEVELS = ['Начинающий', 'Средний', 'Сложный'];

export const COURSES_COLORS: IColor[] = [
  {
    name: 'Lilac',
    hex: '#D2DBFF'
  },
  {
    name: 'Yellow',
    hex: '#FFF09F'
  },
  {
    name: 'Beige',
    hex: '#FEDFCB'
  },
  {
    name: 'Pink',
    hex: '#FFDDDD'
  },
  {
    name: 'Green',
    hex: '#C8F6C6'
  },
  {
    name: 'Blue',
    hex: '#B7EFF2'
  }
];

export const DEFAULT_PAIRS_MATCHING_PAIRS: IPair[] = [
  { original: '', translation: '' },
  { original: '', translation: '' },
  { original: '', translation: '' }
];

export const DEFAULT_AUDIO_VOLUME = 0.5;
export const DEFAULT_VIDEO_VOLUME = 0.2;

export const DEFAULT_CONDITION_MATCHING_PAIRS = {
  blocks: [
    {
      type: 'header',
      data: {
        text: 'Составьте пары слов из предлагаемых слов:',
        level: 2
      }
    }
  ]
};
export const DEFAULT_CONDITION_CHOOSE_ANSWER = {
  blocks: [
    {
      type: 'header',
      data: {
        text: 'Подберите перевод слова:',
        level: 2
      }
    }
  ]
};
export const DEFAULT_CONDITION_MAKING_SENTENCE = {
  blocks: [
    {
      type: 'header',
      data: {
        text: 'Составьте правильный перевод предложения:',
        level: 2
      }
    }
  ]
};
export const DEFAULT_CONDITION_INSERTING_WORDS = {
  blocks: [
    {
      type: 'header',
      data: {
        text: 'Заполните пропуски:',
        level: 2
      }
    }
  ]
};
export const DEFAULT_CONDITION_FILLING_WORDS = {
  blocks: [
    {
      type: 'header',
      data: {
        text: 'Вставьте пропущенные слова:',
        level: 2
      }
    }
  ]
};

export const DEFAULT_EDITOR_DATA: OutputData = {
  blocks: [
    {
      type: 'header',
      data: {
        text: '',
        level: 2
      }
    }
  ]
};

export const DEFAULT_EDITABLE_CONDITION_DATA: OutputData = {
  blocks: [
    {
      type: 'header',
      data: {
        text: '',
        level: 4
      }
    }
  ]
};

export const DEFAULT_EDITABLE_CONDITION_MATCHING_PAIRS: OutputData = {
  blocks: [
    {
      type: 'header',
      data: {
        text: 'Составьте пары из предлагаемых слов:',
        level: 4
      }
    }
  ]
};

export const DEFAULT_EDITABLE_CONDITION_CHOOSE_ANSWER: OutputData = {
  blocks: [
    {
      type: 'header',
      data: {
        text: 'Подберите перевод слова:',
        level: 4
      }
    }
  ]
};

export const DEFAULT_EDITABLE_CONDITION_CHOOSE_SENTENCE: OutputData = {
  blocks: [
    {
      type: 'header',
      data: {
        text: 'Подберите перевод предложения:',
        level: 4
      }
    }
  ]
};

export const DEFAULT_EDITABLE_CONDITION_MAKING_SENTENCE: OutputData = {
  blocks: [
    {
      type: 'header',
      data: {
        text: 'Составьте правильный перевод предложения:',
        level: 4
      }
    }
  ]
};

export const DEFAULT_EDITABLE_CONDITION_FILLING_WORDS: OutputData = {
  blocks: [
    {
      type: 'header',
      data: {
        text: 'Заполните пропуски:',
        level: 4
      }
    }
  ]
};

export const DEFAULT_EDITABLE_CONDITION_INSERTING_WORDS: OutputData = {
  blocks: [
    {
      type: 'header',
      data: {
        text: 'Вставьте пропущенные слова',
        level: 4
      }
    }
  ]
};

export const DEFAULT_EDITABLE_CONDITION_DIALOG: OutputData = {
  blocks: [
    {
      type: 'header',
      data: {
        text: 'Составьте правильный перевод диалога:',
        level: 4
      }
    }
  ]
};

export const DEFAULT_EDITABLE_PURPOSE_DATA: OutputData = {
  blocks: [
    {
      type: 'header',
      data: {
        text: '',
        level: 3
      }
    }
  ]
};

export const MONTH_LABELS = [
  'янв.',
  'фев.',
  'мар.',
  'апр.',
  'май',
  'июн.',
  'июл.',
  'авг.',
  'сен.',
  'окт.',
  'ноя.',
  'дек.'
];

export const WEEKDAY_LABELS = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_NO_MATCH_REGEXP = /[♣☺♂?<>%@//]/g;

// export const TIMECODE_REGEXP = /^([0-5][0-9]):([0-5][0-9])$/;
export const TIMECODE_REGEXP = /^(\d{1,2}:)?\d{2}:\d{2}$/;

export enum FORM_ERROR_MESSAGES {
  REQUIRED_FIELD = 'Необходимое поле',
  DOESNT_CONSIST_OF_SPACES = 'Поле не должно состоять из одних пробелов',
  DOESNT_CONTAIN_SPACES = 'Поле не должно содержать пробелов',
  EQUAL_TO_CURRENT_TITLE = 'Поле должно содержать текущее название',
  INCORRECT_WORD = 'Слово введено неверно',
  INCORRECT_EMAIL = 'Пожалуйста, введите действующий адрес электронной почты',
  INCORRECT_PASSWORD = 'Пароль не должен содержать ?, <, >, %, @, /',
  INCORRECT_TIMECODE = 'Введите таймкод в формате hh:mm:ss',
  MAX_LIMIT = 'Максимальное допустимое количество 75 символов'
}

export const MAKING_SENTENCE_REGEXP = /\S\/|\/\S/;

export enum TEST_STEPS_ERROR_MESSAGES {
  MAKING_SENTENCE = 'Не хватает пробела до и/или после разделителя',
  CHOOSE_ANSWER = 'Варианты ответов не должны повторяться'
}

export const DEFAULT_COURSE_ATTEMPTS_AMOUNT = 3;

export * from './analytics';
export * from './carrot-quest';
export * from './change-history';
export * from './checklist';
export * from './course';
export * from './course-avatars';
export * from './course-learning-objective-icons';
export * from './links-to-web-app';
export * from './months';
export * from './notification';
export * from './react-query';
export * from './steps';
export * from './subscription';
